const INFO = {
	main: {
		title: "Savan Rathod",
		name: "Savan R.",
		email: "savan.rathod@outlook.com",
		logo: "../logo.jpeg",
	},

	socials: {
		twitter: "https://x.com/SAVANRA44940126",
		github: "https://github.com/savan4794",
		linkedin: "https://linkedin.com/savan-rathod",
		instagram: "https://instagram.com/__sam_dev__",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Full-stack web and mobile app developer, and amateur astronaut.",
		description:
			"I am a backend developer with expertise in Laravel. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
	},

	about: {
		title: "I’m Savan Rathod. I live in India, where I design the future.",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Becutan KidsVits Bulgaria",
			description:
				"KidsVits is create and develop medical devices and food supplements in Bulgaria and Macedonia",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/php/php.png",
			linkText: "View Project",
			link: "https://becutankidsvits.bg",
		},

		{
			title: "Good Nature",
			description:
				"Good Nature is premium tea and coffee maker and seller in Macedonia. it's premium tea is famouse Macedonia",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/php/php.png",
			linkText: "View Project",
			link: "https://goodnature.mk",
		},

		{
			title: "G6 Superhomes",
			description:
				"G6 Superhomes create Super Switch Mega Switch for home and offices. this switch is also known as smart switch.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://www.g6superhomes.in",
		}
	],
};

export default INFO;
