const SEO = [
	{
		page: "home",
		description:
			"I am a backend developer with expertise in Laravel. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies like Laravel,Node JS,React JS, Vue JS, Angular JS.",
		keywords: ["Savan Rathod", "Savan R", "Savan","Laravel","Node JS","Developer","Full Stack Developer","Savan Rathod Developer","Savan Rathod Full Stack","Savan Rathod Full Stack Developer"],
	},

	{
		page: "about",
		description:
			"I am a backend developer with expertise in Laravel. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies like Laravel,Node JS,React JS, Vue JS, Angular JS.",
			keywords: ["Savan Rathod", "Savan R", "Savan","Laravel","Node JS","Developer","Full Stack Developer","Savan Rathod Developer","Savan Rathod Full Stack","Savan Rathod Full Stack Developer"],
	},

	{
		page: "articles",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
		keywords: ["Savan Rathod", "Savan R", "Savan","Laravel","Node JS","Developer","Full Stack Developer","Savan Rathod Developer","Savan Rathod Full Stack","Savan Rathod Full Stack Developer"],
	},

	{
		page: "projects",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. I am available for Work and contribute to.",
		keywords: ["Savan Rathod", "Savan R", "Savan","Laravel","Node JS","Developer","Full Stack Developer","Savan Rathod Developer","Savan Rathod Full Stack","Savan Rathod Full Stack Developer"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["Savan Rathod", "Savan R", "Savan","Laravel","Node JS","Developer","Full Stack Developer","Savan Rathod Developer","Savan Rathod Full Stack","Savan Rathod Full Stack Developer"],
	},
];

export default SEO;
